
import { BrowserRouter , Route,Routes, Link} from 'react-router-dom'
import Navbar from '../components/Navbar'
import { useState } from "react";
import Customers from "./customers"
import React from "react";
import "../components/Video.css";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { 
 Card,CardImg, CardBody,CardLink, 
    CardTitle, CardText
} from "reactstrap"
import '../components/cards.css';
import img from "../components/assets/pilates.webp"
import ReactDOM from "react-dom/client";
import { Button } from "rsuite"; 
import { Scrollbar } from 'react-scrollbars-custom';
// Import the default CSS 
import "rsuite/dist/rsuite.min.css";
import JSZip, { files } from 'jszip';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone"
import Dropzone from "react-dropzone";
import "../components/DragDropFiles.css";


function Video() {
  
    const navigate = useNavigate();
    // Filters
    const[image,setImage] = useState();
    const [vidinfo, setVidinfo] = useState(null);
    // Image name   // Video name  // Image itself 
    const [files, setFiles] = useState([]); 
    const [origin, setOrigin] = useState([]); 
    const [file, setFile] = useState(null);
    const [trigger, setTrigger] = useState(0);
  

    const handleUpload = async (acceptedFiles) =>{
      if (acceptedFiles.length != 1){ // if there is more than one file we upload straight to server
      let count =0;
      for (let i =0; i< acceptedFiles.length; i++){ // loop through files and check correct file type

        if (!acceptedFiles[i].name.match(/\.(mp4|mov|wmv|avi|avchd|webm|flv)$/))
          {
            alert('Incorrect file type')
            return;
          }

        else{
          //create data and append each file 
        let formData = new FormData 
        formData.append("file", acceptedFiles[i]);
        try{
        const response = await axios.post("https://springapi-xsvy.onrender.com/defupload",formData)
        .then((response) => {
          //console.log(formData)
          alert(acceptedFiles[i].name.toString() + "uploaded")
          count += 1;
          setFile(acceptedFiles); 
          if (count == acceptedFiles.length) {setTrigger(trigger+1);} //once we have uploaded all videos, refresh the video scrollbar 
        }
      
      )
      }
        catch(error) {
          console.error(error);
          alert('Upload failed')
        }
        }
      }
      // Change files below

    }
    else{ 
      if (!acceptedFiles[0].name.match(/\.(mp4|mov|wmv|avi|avchd|webm|flv)$/))
        {
          alert('incorrect file type')
        }
      else{ //if just one file we navigate to video-form.js page
        setFile(acceptedFiles)
        console.log({"file":acceptedFiles}) // test
        navigate('/edit', {state:{"file":acceptedFiles}}); //take the file with us when we change screen
      }
      }
    }
  

    function deletevid(identifier){
    
        const { data } = axios.post('https://springapi-xsvy.onrender.com/delete', {
            id: identifier,
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        setFiles(
             files.filter(card =>
              card.id !== identifier
            )
        );
        setOrigin(
          origin.filter(card =>
           card.id !== identifier
         )
     );
        
    }
    function editvid(row,index){
      navigate('/editprev', {state:{"file":row.filename,"vidname":row.vidname,"details":row.change,"id":row.id,"imgname":row.imgname}});
            
    }
    function search(term){
      setFiles(origin.filter((info)=>{

            return info.vidname.toUpperCase().includes(term.toUpperCase());
      }))
    }
    useEffect(() => {

        const options = {
            method: 'GET',
            url: 'https://springapi-xsvy.onrender.com/downloadtitles',
            responseType: 'blob', //important
        };
   
        axios.request(options).then(function (response) {
            const downloadUrl = new Blob([response.data]);
            var zip = new JSZip();
            zip.loadAsync(downloadUrl).then(function(zip) {
              // Remove loop and fetch csv directly
              let file = zip.files["Filters.csv"];
              const csvcontent = file.async("text").then(csvcontent => {       
                let processedData = csvcontent.split("\n"); 
                processedData = processedData.slice(1, -1);
                setVidinfo(processedData);
                setFiles([]);
                setOrigin([]);
                zip.files["default_img.jpg"].async("blob").then(img => {
          
                /*const image = new Image();
                image.src = URL.createObjectURL(img);     
                setImage(image.src);*/
          
              });
                // Set videos and images
                for (let i = 0; i < processedData.length; i++) {
                    let row = processedData[i].split(",");
                    let info = {colour: "",imgname: "",filename:"", vidname: "",imgupload:null, video: null, image: null, id: "",change:row.slice(3,14)};
                    info.id = row[0];
                    info.filename = row[1];       
                    info.vidname = row[3].replace(/['"]+/g, '');

                    info.imgname = row[2];

                    if (row[2] == "default_img.jpg"){
                      info.colour= "white";
                    }
                    else{
                      info.colour = "success";
                    }
                    /*if (!Object.keys(zip.files).includes(info.imgname)) {
                        info.imgname = "default_img.jpg";
                    } */
                    // Generate image  
                    setFiles(files=> {
                      return [
                        ...files, // merge previous state
                       info,
                      ]
                    })
                
                    setOrigin(origin =>{ 
                      return [
                      ...origin,
                     info,
                    ]})
                    
                
                  
      
                   
                }
            });
            
            }).catch(function (error) {
              alert("Error");
            });
          });
          
 
     },[trigger]);
     
  const styles = {
    maxWidth: 2000,
    width: "110%",
    height: 500,
  };
  
  return (
    <>
    <div className="App">
    <Navbar/>

    <div className="cont">
    <div className="main-container">
        <Dropzone className="drag-drop" onDrop={handleUpload}>
          
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
           
  
            return (
              <div
                {...getRootProps({
                  className: `dropzone `,
                })}
              >
                <input {...getInputProps()} />
                <p>Drag'n'drop video, or click to select files</p>
                <br/>
                <button className="button">Upload file</button>
              </div>
            );
          }}
          
        </Dropzone>
      
        
      </div>
            <br/>
            <h5>Uploaded Videos</h5>
            <br/>
           
            <input onChange={(e)=> search(e.target.value)}className="searchvideo" placeholder="      Search Videos"></input>
            <div style={{ 
            display: 'block', width: 700, padding: 30 }}> 
            
            <Scrollbar style={styles}>

                {
                   files.map((json, index)=> 
                   <Card  color = {json.colour}>
                    
                     <CardBody>
                         <CardTitle className="cardtitle">{json.vidname}</CardTitle> 
                         <Button appearance="primary" id = "edit" onClick={()=> editvid(json,index)} className="cardedit" >Edit </Button>
                         {/* Delete button has been comment out because it is dangerous (deletes without confirmation)
                          <Button  onClick={() => deletevid(json.id)} appearance="primary" color = "red" className="carddel">Delete</Button> */}
                          
                     </CardBody> 
                 </Card> )
                 
                }
            </Scrollbar>
            
        </div> 
          
             </div>
            
             </div>
    </>
    
    
  );
}

//<CardImg className="cardimg"  src={image}    /> 
export default Video;